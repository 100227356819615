import "./VersionElement.scss";

const versions = [
  {
    name: "version 1.3",
    changes: [
      "remove portrait mode.",
      "improve download area, the area size is now calculated based on the content.",
      "mark experimental features with beta tag.",
      "audio is now disabled by default.",
    ],
  },
  {
    name: "version 1.2",
    changes: ["bugfixes: spicy sauce and demon horns"],
  },
  {
    name: "version 1.1",
    changes: ["set audio.volume to 0.2"],
  },
  {
    name: "version 1.0",
    changes: ["leaving beta", "add portrait mode"],
  },
  {
    name: "beta1.2.2",
    changes: ["fixing download area"],
  },
  {
    name: "beta1.2.1",
    changes: [
      "add og tags in header for sharing url",
      "disabling the sound is now not playing the sound anymore for the click",
    ],
  },
  {
    name: "beta1.2.0",
    changes: ["add backgrounds", "new settings ui for button groups"],
  },
  {
    name: "beta1.1.1",
    changes: ["add discord icon"],
  },
  {
    name: "beta1.1.0",
    changes: [
      "fix shadow position bug",
      "show close icon when in settings",
      "new features: undo and redo",
      "adding history support",
      "scroll to selected category in category selector",
    ],
  },
  {
    name: "beta1.0.0",
    changes: ["polished settings"],
  },
  {
    name: "beta0.1.0",
    changes: ["add weapon which follows the mouse", "enable/disable shadow"],
  },
  {
    name: "beta0.0.9",
    changes: ["adding audio"],
  },
  {
    name: "beta0.0.8",
    changes: [
      "add redux to manage states",
      "settings close on click settings again",
    ],
  },
  {
    name: "beta0.0.7",
    changes: ["shadow and animation added!"],
  },
  {
    name: "beta0.0.6",
    changes: ["legs added!", "add character items"],
  },
  {
    name: "beta0.0.5",
    changes: ["mobile friendly categry selection", "remove temp save button"],
  },
  {
    name: "beta0.0.4",
    changes: [
      "all tab",
      "settings to switch item and icon mode and hide labels",
      "search field to filter",
      "SHUFFLE BUTTON",
      "CLEAR ALL BUTTON",
    ],
  },
  {
    name: "beta0.0.3",
    changes: ["redesign", "fixing bait bug"],
  },
  {
    name: "beta0.0.2",
    changes: ["change favicon and logos", "change title and content meta tag"],
  },
  {
    name: "beta0.0.1",
    changes: ["add version", "add version popup"],
  },
];

export const version = versions[0].name;

function VersionElement() {
  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        margin: 20,
      }}
    >
      {versions.map((version, index) => {
        return (
          <div key={index}>
            <span key={"span" + version.name}>{version.name}</span>
            <ul
              key={"ul" + version.name}
              style={{
                width: "fit-content",
              }}
            >
              {version.changes.map((change, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      width: "fit-content",
                    }}
                  >
                    {change}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default VersionElement;
