import {
  APPEARANCE_OBJECT,
  CATEGORY,
  ITEM_OBJECT,
  POSITIONS,
} from "./appearance_types";
import all from "./data";

export class Appearance {
  public readonly id: string | undefined;
  public readonly name: string | undefined;
  public readonly path: string;
  public readonly position: number;
  public readonly priority: number;
  public readonly depth: number;
  public readonly hash: string | undefined;

  constructor(appearance: APPEARANCE_OBJECT) {
    this.id = appearance.id;
    this.name = appearance.name;
    this.path = appearance.path;
    this.position = appearance.position;
    this.priority = appearance.priority;
    this.depth = appearance.depth;
    this.hash = appearance.hash;
  }

  public getPositionName(): string {
    return POSITIONS[this.position];
  }

  public getPositionNumber(): number {
    return POSITIONS.indexOf(this.getPositionName());
  }
}

export class Item {
  public readonly id: string;
  public readonly name: string;
  public readonly icon: string;
  public readonly category: CATEGORY;
  public readonly appearance: Appearance[];

  constructor(item: ITEM_OBJECT) {
    this.id = item.id;
    this.name = item.name;
    this.icon = item.icon;
    this.category = item.category;
    this.appearance = Array.isArray(item.appearance)
      ? item.appearance.map((appearance) => new Appearance(appearance))
      : [new Appearance(item.appearance)];
  }
}

export class ItemManager {
  private static _instance: ItemManager;
  public groups: { [key: string]: Item[] } = {};
  public items: Item[] = Object.values(all).map(
    (item: ITEM_OBJECT) => new Item(item)
  );

  private constructor() {
    this.groups = this.groupByPosition(this.items);
  }

  public static get instance(): ItemManager {
    if (!this._instance) {
      this._instance = new ItemManager();
    }
    return this._instance;
  }

  public getItemById(id: string): Item | undefined {
    return this.items.find((item) => item.id === id);
  }

  public shuffle(): string[] {
    const mustHave = ["eyes", "mouth"];

    const keys = Object.keys(this.groups);
    const shuffled: string[] = ["base"];

    keys.forEach((key) => {
      //random item of each group
      if (!mustHave.includes(key) && Math.random() < 0.7) {
        return;
      }
      const randomIndex = Math.floor(Math.random() * this.groups[key].length);
      shuffled.push(this.groups[key][randomIndex].id);
    });
    return shuffled;
  }

  public groupByPosition(items: Item[]): { [key: string]: Item[] } {
    const groupedItems: { [key: string]: Item[] } = {};
    items.forEach((item) => {
      item.appearance.forEach((appearance) => {
        const position = appearance.getPositionName();
        if (!groupedItems[position]) {
          groupedItems[position] = [];
        }
        groupedItems[position].push(item);
      });
      return;
    });
    return groupedItems;
  }

  public itemListToHash(items: Item[]): string {
    return items
      .map((item) => item.appearance.map((app) => app.hash))
      .flat()
      .sort()
      .join("");
  }
}
