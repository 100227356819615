import { createSlice } from "@reduxjs/toolkit";

export interface HistoryState {
  active: string[];
  undo: string[][];
  redo: string[][];
}

const initialState = {
  active: [],
  undo: [],
  redo: [],
} satisfies HistoryState as HistoryState;

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    undo: (state) => {
      const last = state.undo.pop();
      if (last) {
        state.redo.push(state.active);
        state.active = last;
      }
    },
    redo: (state) => {
      const last = state.redo.pop();
      if (last) {
        state.undo.push(state.active);
        state.active = last;
      }
    },
    add: (state, action) => {
      if (state.active.length > 0) state.undo.push(state.active);
      state.redo = [];
      state.active = action.payload;
    },
    addWithoutSave: (state, action) => {
      state.active = action.payload;
    },
    clearActive: (state) => {
      if (state.active.length > 0) state.undo.push(state.active);
      state.active = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { undo, redo, add, addWithoutSave, clearActive } =
  historySlice.actions;

export default historySlice.reducer;
