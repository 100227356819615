import { items as rawitems } from "./items";
import { characters } from "./characters";
import { APPEARANCE_OBJECT, CATEGORY, ITEM_OBJECT } from "../appearance_types";

const objects = { ...rawitems, ...characters };

const items = Object.keys(objects).reduce(
  (p: { [key: string]: ITEM_OBJECT }, c) => {
    const obj: ITEM_OBJECT = objects[c];
    if (obj.category === CATEGORY.CHARACTER && Array.isArray(obj.appearance)) {
      return {
        ...p,
        ...obj.appearance.reduce(
          (p_app: any, appearance: APPEARANCE_OBJECT) => {
            if (!appearance?.id) return p_app;
            if (p_app?.[appearance.id])
              return {
                ...p_app,
                [appearance.id]: {
                  ...p_app[appearance.id],
                  appearance: [appearance, p_app[appearance.id].appearance],
                },
              };
            return {
              ...p_app,
              [appearance.id]: {
                id: appearance.id,
                name: appearance.name,
                icon: obj.icon,
                category: obj.category,
                appearance: appearance,
              },
            };
          },
          {}
        ),
      };
    }
    return { ...p, [c]: obj };
  },
  {}
);

export default items;
