const backgrounds: string[] = [
  "tiles_1",
  "tiles_2",
  "tiles_3",
  "tiles_4",
  "tiles_5",
  "tiles_6",
];

export { backgrounds };
