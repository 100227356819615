import { CATEGORY, ITEM_OBJECT } from "../appearance_types";

export const items: { [key: string]: ITEM_OBJECT } = {
  acid: {
    id: "acid",
    name: "Acid",
    icon: "assets/acid_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Aa",
      path: "assets/acid.png",
      depth: 250,
      position: 8,
      priority: 1,
    },
  },
  adrenaline: {
    id: "adrenaline",
    name: "Adrenaline",
    icon: "assets/adrenaline_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ab",
      path: "assets/adrenaline.png",
      depth: 1300,
      position: 2,
      priority: 2,
    },
  },
  alien_baby: {
    id: "alien_baby",
    name: "Alien Baby",
    icon: "assets/alien_baby_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ac",
      path: "assets/alien_baby.png",
      depth: 600,
      position: 10,
      priority: 3,
    },
  },
  alien_eyes: {
    id: "alien_eyes",
    name: "Alien Eyes",
    icon: "assets/alien_eyes_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ad",
      path: "assets/alien_eyes.png",
      depth: 500,
      position: 6,
      priority: 2,
    },
  },
  alien_magic: {
    id: "alien_magic",
    name: "Alien Magic",
    icon: "assets/alien_magic_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ae",
      path: "assets/alien_magic.png",
      depth: 500,
      position: 6,
      priority: 3,
    },
  },
  alien_tongue: {
    id: "alien_tongue",
    name: "Alien Tongue",
    icon: "assets/alien_tongue_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Af",
      path: "assets/alien_tongue.png",
      depth: 250,
      position: 8,
      priority: 2,
    },
  },
  alien_worm: {
    id: "alien_worm",
    name: "Alien Worm",
    icon: "assets/alien_worm_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ag",
      path: "assets/alien_worm.png",
      depth: 400,
      position: 12,
      priority: 0,
    },
  },
  alloy: {
    id: "alloy",
    name: "Alloy",
    icon: "assets/alloy_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ah",
      path: "assets/alloy.png",
      depth: 1,
      position: 0,
      priority: 3,
    },
  },
  anvil: {
    id: "anvil",
    name: "Anvil",
    icon: "assets/anvil_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ai",
      path: "assets/anvil.png",
      depth: 1,
      position: 14,
      priority: 3,
    },
  },
  baby_elephant: {
    id: "baby_elephant",
    name: "Baby Elephant",
    icon: "assets/baby_elephant_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Aj",
      path: "assets/baby_elephant.png",
      depth: 620,
      position: 7,
      priority: 0,
    },
  },
  baby_gecko: {
    id: "baby_gecko",
    name: "Baby Gecko",
    icon: "assets/baby_gecko_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ak",
      path: "assets/baby_gecko.png",
      depth: -1010,
      position: 15,
      priority: 0,
    },
  },
  baby_with_a_beard: {
    id: "baby_with_a_beard",
    name: "Baby with a Beard",
    icon: "assets/baby_with_a_beard_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Al",
      path: "assets/baby_with_a_beard.png",
      depth: 550,
      position: 8,
      priority: 4,
    },
  },
  bag: {
    id: "bag",
    name: "Bag",
    icon: "assets/bag_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Am",
      path: "assets/bag.png",
      depth: 400,
      position: 12,
      priority: 1,
    },
  },
  bait: {
    id: "bait",
    name: "Bait",
    icon: "assets/bait_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "An",
        path: "assets/bait_0.png",
        depth: 600,
        position: 16,
        priority: 2,
      },
      {
        hash: "An",
        path: "assets/bait_1.png",
        depth: -100,
        position: 17,
        priority: 2,
      },
    ],
  },
  ball_and_chain: {
    id: "ball_and_chain",
    name: "Ball and Chain",
    icon: "assets/ball_and_chain_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: [
      {
        hash: "Ao",
        path: "assets/ball_and_chain_0.png",
        depth: 400,
        position: 12,
        priority: 2,
      },
      {
        hash: "Ao",
        path: "assets/ball_and_chain_1.png",
        depth: -500,
        position: 9,
        priority: 2,
      },
    ],
  },
  bandana: {
    id: "bandana",
    name: "Bandana",
    icon: "assets/bandana_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ap",
      path: "assets/bandana.png",
      depth: 1000,
      position: 4,
      priority: 3,
    },
  },
  banner: {
    id: "banner",
    name: "Banner",
    icon: "assets/banner_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Aq",
      path: "assets/banner.png",
      depth: -1000,
      position: 9,
      priority: 2,
    },
  },
  barricade: {
    id: "barricade",
    name: "Barricade",
    icon: "assets/barricade_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ar",
      path: "assets/barricade.png",
      depth: 1300,
      position: 5,
      priority: 3,
    },
  },
  base: {
    id: "base",
    name: "Base",
    icon: "assets/base_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "As",
      path: "assets/base.png",
      depth: 0,
      position: 14,
      priority: 3,
    },
  },
  bat: {
    id: "bat",
    name: "Bat",
    icon: "assets/bat_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "At",
      path: "assets/bat.png",
      depth: -1000,
      position: 9,
      priority: 2,
    },
  },
  bean_teacher: {
    id: "bean_teacher",
    name: "Bean Teacher",
    icon: "assets/bean_teacher_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Au",
      path: "assets/bean_teacher.png",
      depth: 320,
      position: 11,
      priority: 2,
    },
  },
  beanie: {
    id: "beanie",
    name: "Beanie",
    icon: "assets/beanie_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Av",
      path: "assets/beanie.png",
      depth: 1000,
      position: 4,
      priority: 0,
    },
  },
  big_arms: {
    id: "big_arms",
    name: "Big Arms",
    icon: "assets/big_arms_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Aw",
      path: "assets/big_arms.png",
      depth: -60,
      position: 17,
      priority: 4,
    },
  },
  black_belt: {
    id: "black_belt",
    name: "Black Belt",
    icon: "assets/black_belt_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ax",
      path: "assets/black_belt.png",
      depth: 401,
      position: 10,
      priority: 2,
    },
  },
  blindfold: {
    id: "blindfold",
    name: "Blindfold",
    icon: "assets/blindfold_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ay",
      path: "assets/blindfold.png",
      depth: 800,
      position: 6,
      priority: 2,
    },
  },
  blood_donation: {
    id: "blood_donation",
    name: "Blood Donation",
    icon: "assets/blood_donation_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Az",
      path: "assets/blood_donation.png",
      depth: 450,
      position: 12,
      priority: 2,
    },
  },
  blood_leech: {
    id: "blood_leech",
    name: "Blood Leech",
    icon: "assets/blood_leech_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ba",
      path: "assets/blood_leech.png",
      depth: 250,
      position: 8,
      priority: 2,
    },
  },
  bloody_hand: {
    id: "bloody_hand",
    name: "Bloody Hand",
    icon: "assets/bloody_hand_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bb",
      path: "assets/bloody_hand.png",
      depth: 1,
      position: 14,
      priority: 3,
    },
  },
  boiling_water: {
    id: "boiling_water",
    name: "Boiling Water",
    icon: "assets/boiling_water_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bc",
      path: "assets/boiling_water.png",
      depth: 25,
      position: 0,
      priority: 0,
    },
  },
  book: {
    id: "book",
    name: "Book",
    icon: "assets/book_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bd",
      path: "assets/book.png",
      depth: 1000,
      position: 4,
      priority: 0,
    },
  },
  bowler_hat: {
    id: "bowler_hat",
    name: "Bowler Hat",
    icon: "assets/bowler_hat_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Be",
      path: "assets/bowler_hat.png",
      depth: 1100,
      position: 4,
      priority: 3,
    },
  },
  boxing_glove: {
    id: "boxing_glove",
    name: "Boxing Glove",
    icon: "assets/boxing_glove_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bf",
      path: "assets/boxing_glove.png",
      depth: 1200,
      position: 3,
      priority: 0,
    },
  },
  broken_mouth: {
    id: "broken_mouth",
    name: "Broken Mouth",
    icon: "assets/broken_mouth_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bg",
      path: "assets/broken_mouth.png",
      depth: 450,
      position: 8,
      priority: 0,
    },
  },
  butterfly: {
    id: "butterfly",
    name: "Butterfly",
    icon: "assets/butterfly_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bh",
      path: "assets/butterfly.png",
      depth: -1000,
      position: 9,
      priority: 2,
    },
  },
  cake: {
    id: "cake",
    name: "Cake",
    icon: "assets/cake_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bi",
      path: "assets/cake.png",
      depth: 500,
      position: 6,
      priority: 0,
    },
  },
  campfire: {
    id: "campfire",
    name: "Campfire",
    icon: "assets/campfire_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bj",
      path: "assets/campfire.png",
      depth: -1100,
      position: 1,
      priority: 0,
    },
  },
  candle: {
    id: "candle",
    name: "Candle",
    icon: "assets/candle_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bk",
      path: "assets/candle.png",
      depth: 1200,
      position: 1,
      priority: 0,
    },
  },
  cape: {
    id: "cape",
    name: "Cape",
    icon: "assets/cape_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bl",
      path: "assets/cape.png",
      depth: -1000,
      position: 9,
      priority: 4,
    },
  },
  celery_tea: {
    id: "celery_tea",
    name: "Celery Tea",
    icon: "assets/celery_tea_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Bm",
      path: "assets/celery_tea.png",
      depth: 250,
      position: 8,
      priority: 2,
    },
  },
  chameleon: {
    id: "chameleon",
    name: "Chameleon",
    icon: "assets/chameleon_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bn",
      path: "assets/chameleon.png",
      depth: -1001,
      position: 15,
      priority: 3,
    },
  },
  charcoal: {
    id: "charcoal",
    name: "Charcoal",
    icon: "assets/charcoal_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bo",
      path: "assets/charcoal.png",
      depth: 25,
      position: 0,
      priority: 0,
    },
  },
  claw_tree: {
    id: "claw_tree",
    name: "Claw Tree",
    icon: "assets/claw_tree_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bp",
      path: "assets/claw_tree.png",
      depth: 600,
      position: 13,
      priority: 0,
    },
  },
  clockwork_wasp: {
    id: "clockwork_wasp",
    name: "Clockwork Wasp",
    icon: "assets/clockwork_wasp_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Bq",
      path: "assets/clockwork_wasp.png",
      depth: 500,
      position: 1,
      priority: 1,
    },
  },
  clover: {
    id: "clover",
    name: "Clover",
    icon: "assets/clover_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Br",
      path: "assets/clover.png",
      depth: 1100,
      position: 2,
      priority: 3,
    },
  },
  coffee: {
    id: "coffee",
    name: "Coffee",
    icon: "assets/coffee_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bs",
      path: "assets/coffee.png",
      depth: 100,
      position: 1,
      priority: 0,
    },
  },
  cog: {
    id: "cog",
    name: "Cog",
    icon: "assets/cog_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bt",
      path: "assets/cog.png",
      depth: 50.0,
      position: 0,
      priority: 1,
    },
  },
  coil: {
    id: "coil",
    name: "Coil",
    icon: "assets/coil_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Bu",
      path: "assets/coil.png",
      depth: 1300,
      position: 1,
      priority: 1,
    },
  },
  community_support: {
    id: "community_support",
    name: "Community Support",
    icon: "assets/community_support_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bv",
      path: "assets/community_support.png",
      depth: -500.0,
      position: 9,
      priority: 3,
    },
  },
  compass: {
    id: "compass",
    name: "Compass",
    icon: "assets/compass_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bw",
      path: "assets/compass.png",
      depth: 400,
      position: 5,
      priority: 2,
    },
  },
  coupon: {
    id: "coupon",
    name: "Coupon",
    icon: "assets/coupon_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Bx",
      path: "assets/coupon.png",
      depth: -100.0,
      position: 13,
      priority: 0,
    },
  },
  crown: {
    id: "crown",
    name: "Crown",
    icon: "assets/crown_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "By",
      path: "assets/crown.png",
      depth: 1000,
      position: 4,
      priority: 4,
    },
  },
  cute_monkey: {
    id: "cute_monkey",
    name: "Cute Monkey",
    icon: "assets/cute_monkey_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "Bz",
        path: "assets/cute_monkey_0.png",
        depth: 600,
        position: 16,
        priority: 0,
      },
      {
        hash: "Bz",
        path: "assets/cute_monkey_1.png",
        depth: -200,
        position: 17,
        priority: 0,
      },
    ],
  },
  cyberball: {
    id: "cyberball",
    name: "Cyberball",
    icon: "assets/cyberball_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ca",
      path: "assets/cyberball.png",
      depth: 1,
      position: 14,
      priority: 0,
    },
  },
  cyclops_worm: {
    id: "cyclops_worm",
    name: "Cyclops Worm",
    icon: "assets/cyclops_worm_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cb",
      path: "assets/cyclops_worm.png",
      depth: 500,
      position: 6,
      priority: 1,
    },
  },
  dangerous_bunny: {
    id: "dangerous_bunny",
    name: "Dangerous Bunny",
    icon: "assets/dangerous_bunny_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cc",
      path: "assets/dangerous_bunny.png",
      depth: 400,
      position: 12,
      priority: 2,
    },
  },
  decomposing_flesh: {
    id: "decomposing_flesh",
    name: "Decomposing Flesh",
    icon: "assets/decomposing_flesh_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Cd",
      path: "assets/decomposing_flesh.png",
      depth: 1,
      position: 14,
      priority: 3,
    },
  },
  defective_steroids: {
    id: "defective_steroids",
    name: "Defective Steroids",
    icon: "assets/defective_steroids_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ce",
      path: "assets/defective_steroids.png",
      depth: 1200,
      position: 3,
      priority: 0,
    },
  },
  diploma: {
    id: "diploma",
    name: "Diploma",
    icon: "assets/diploma_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cf",
      path: "assets/diploma.png",
      depth: 1300,
      position: 6,
      priority: 3,
    },
  },
  duct_tape: {
    id: "duct_tape",
    name: "Duct Tape",
    icon: "assets/duct_tape_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cg",
      path: "assets/duct_tape.png",
      depth: 50,
      position: 0,
      priority: 0,
    },
  },
  dynamite: {
    id: "dynamite",
    name: "Dynamite",
    icon: "assets/dynamite_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ch",
      path: "assets/dynamite.png",
      depth: 1200,
      position: 1,
      priority: 0,
    },
  },
  energy_bracelet: {
    id: "energy_bracelet",
    name: "Energy Bracelet",
    icon: "assets/energy_bracelet_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ci",
      path: "assets/energy_bracelet.png",
      depth: 450,
      position: 10,
      priority: 2,
    },
  },
  estys_couch: {
    id: "estys_couch",
    name: "Esty's Couch",
    icon: "assets/estys_couch_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cj",
      path: "assets/estys_couch.png",
      depth: -1000,
      position: 9,
      priority: 4,
    },
  },
  exoskeleton: {
    id: "exoskeleton",
    name: "Exoskeleton",
    icon: "assets/exoskeleton_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "Ck",
        path: "assets/exoskeleton_0.png",
        depth: 800,
        position: 16,
        priority: 4,
      },
      {
        hash: "Ck",
        path: "assets/exoskeleton_1.png",
        depth: -75,
        position: 17,
        priority: 4,
      },
    ],
  },
  extra_stomach: {
    id: "extra_stomach",
    name: "Extra Stomach",
    icon: "assets/extra_stomach_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cl",
      path: "assets/extra_stomach.png",
      depth: 450,
      position: 12,
      priority: 3,
    },
  },
  eye_surgery: {
    id: "eye_surgery",
    name: "Eye Surgery",
    icon: "assets/eye_surgery_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cm",
      path: "assets/eye_surgery.png",
      depth: 500,
      position: 6,
      priority: 1,
    },
  },
  fairy: {
    id: "fairy",
    name: "Fairy",
    icon: "assets/fairy_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cn",
      path: "assets/fairy.png",
      depth: 500,
      position: 6,
      priority: 3,
    },
  },
  fertilizer: {
    id: "fertilizer",
    name: "Fertilizer",
    icon: "assets/fertilizer_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Co",
      path: "assets/fertilizer.png",
      depth: 450,
      position: 12,
      priority: 0,
    },
  },
  fin: {
    id: "fin",
    name: "Fin",
    icon: "assets/fin_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cp",
      path: "assets/fin.png",
      depth: 750,
      position: 1,
      priority: 2,
    },
  },
  focus: {
    id: "focus",
    name: "Focus",
    icon: "assets/focus_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cq",
      path: "assets/focus.png",
      depth: 50,
      position: 5,
      priority: 4,
    },
  },
  fried_rice: {
    id: "fried_rice",
    name: "Fried Rice",
    icon: "assets/fried_rice_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Cr",
      path: "assets/fried_rice.png",
      depth: -500,
      position: 9,
      priority: 1,
    },
  },
  frozen_heart: {
    id: "frozen_heart",
    name: "Frozen Heart",
    icon: "assets/frozen_heart_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Cs",
      path: "assets/frozen_heart.png",
      depth: 401,
      position: 10,
      priority: 3,
    },
  },
  fuel_tank: {
    id: "fuel_tank",
    name: "Fuel Tank",
    icon: "assets/fuel_tank_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ct",
      path: "assets/fuel_tank.png",
      depth: -500,
      position: 9,
      priority: 2,
    },
  },
  gambling_token: {
    id: "gambling_token",
    name: "Gambling Token",
    icon: "assets/gambling_token_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cu",
      path: "assets/gambling_token.png",
      depth: 50,
      position: 5,
      priority: 2,
    },
  },
  gentle_alien: {
    id: "gentle_alien",
    name: "Gentle Alien",
    icon: "assets/gentle_alien_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cv",
      path: "assets/gentle_alien.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  giant_belt: {
    id: "giant_belt",
    name: "Giant Belt",
    icon: "assets/giant_belt_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cw",
      path: "assets/giant_belt.png",
      depth: 401,
      position: 10,
      priority: 4,
    },
  },
  glass_cannon: {
    id: "glass_cannon",
    name: "Glass Cannon",
    icon: "assets/glass_cannon_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cx",
      path: "assets/glass_cannon.png",
      depth: 1300,
      position: 3,
      priority: 3,
    },
  },
  glasses: {
    id: "glasses",
    name: "Glasses",
    icon: "assets/glasses_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cy",
      path: "assets/glasses.png",
      depth: 1200,
      position: 6,
      priority: 2,
    },
  },
  gnome: {
    id: "gnome",
    name: "Gnome",
    icon: "assets/gnome_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Cz",
      path: "assets/gnome.png",
      depth: 1100,
      position: 4,
      priority: 4,
    },
  },
  goat_skull: {
    id: "goat_skull",
    name: "Goat Skull",
    icon: "assets/goat_skull_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "Da",
        path: "assets/goat_skull_0.png",
        depth: 1100,
        position: 2,
        priority: 0,
      },
      {
        hash: "Da",
        path: "assets/goat_skull_1.png",
        depth: -1000,
        position: 3,
        priority: 0,
      },
    ],
  },
  greek_fire: {
    id: "greek_fire",
    name: "Greek Fire",
    icon: "assets/greek_fire_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Db",
      path: "assets/greek_fire.png",
      depth: -500,
      position: 9,
      priority: 3,
    },
  },
  grinds_magical_leaf: {
    id: "grinds_magical_leaf",
    name: "Grind's Magical Leaf",
    icon: "assets/grinds_magical_leaf_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/grinds_magical_leaf.png",
      hash: "Dc",
      depth: 1300,
      position: 2,
      priority: 4,
    },
  },
  gummy_berserker: {
    id: "gummy_berserker",
    name: "Gummy Berserker",
    icon: "assets/gummy_berserker_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/gummy_berserker.png",
      hash: "Dd",
      depth: 250,
      position: 8,
      priority: 0,
    },
  },
  handcuffs: {
    id: "handcuffs",
    name: "Handcuffs",
    icon: "assets/handcuffs_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/handcuffs.png",
      hash: "De",
      depth: 401,
      position: 10,
      priority: 3,
    },
  },
  head_injury: {
    id: "head_injury",
    name: "Head Injury",
    icon: "assets/head_injury_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/head_injury.png",
      hash: "Df",
      depth: 150,
      position: 0,
      priority: 0,
    },
  },
  hedgehog: {
    id: "hedgehog",
    name: "Hedgehog",
    icon: "assets/hedgehog_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/hedgehog.png",
      hash: "Dg",
      depth: 1000,
      position: 4,
      priority: 0,
    },
  },
  helmet: {
    id: "helmet",
    name: "Helmet",
    icon: "assets/helmet_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/helmet.png",
      hash: "Dh",
      depth: 1000,
      position: 4,
      priority: 1,
    },
  },
  honey: {
    id: "honey",
    name: "Honey",
    icon: "assets/honey_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Di",
      path: "assets/honey.png",
      depth: 500,
      position: 6,
      priority: 2,
    },
  },
  hunting_trophy: {
    id: "hunting_trophy",
    name: "Hunting Trophy",
    icon: "assets/hunting_trophy_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "Dj",
        path: "assets/hunting_trophy_0.png",
        depth: 1000,
        position: 2,
        priority: 3,
      },
      {
        hash: "Dj",
        path: "assets/hunting_trophy_1.png",
        depth: -75,
        position: 3,
        priority: 3,
      },
    ],
  },
  ice_cube: {
    id: "ice_cube",
    name: "Ice Cube",
    icon: "assets/ice_cube_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Dk",
      path: "assets/ice_cube.png",
      depth: 401,
      position: 10,
      priority: 0,
    },
  },
  improved_tools: {
    id: "improved_tools",
    name: "Improved Tools",
    icon: "assets/improved_tools_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dl",
      path: "assets/improved_tools.png",
      depth: 400,
      position: 12,
      priority: 2,
    },
  },
  injection: {
    id: "injection",
    name: "Injection",
    icon: "assets/injection_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dm",
      path: "assets/injection.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  insanity: {
    id: "insanity",
    name: "Insanity",
    icon: "assets/insanity_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dn",
      path: "assets/insanity.png",
      depth: 500,
      position: 6,
      priority: 0,
    },
  },
  jelly: {
    id: "jelly",
    name: "Jelly",
    icon: "assets/jelly_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Do",
      path: "assets/jelly.png",
      depth: 1300,
      position: 1,
      priority: 1,
    },
  },
  jetpack: {
    id: "jetpack",
    name: "Jetpack",
    icon: "assets/jetpack_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dp",
      path: "assets/jetpack.png",
      depth: -1000,
      position: 9,
      priority: 4,
    },
  },
  leather_vest: {
    id: "leather_vest",
    name: "Leather Vest",
    icon: "assets/leather_vest_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dq",
      path: "assets/leather_vest.png",
      depth: 320,
      position: 11,
      priority: 2,
    },
  },
  lemonade: {
    id: "lemonade",
    name: "Lemonade",
    icon: "assets/lemonade_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dr",
      path: "assets/lemonade.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  lens: {
    id: "lens",
    name: "Lens",
    icon: "assets/lens_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ds",
      path: "assets/lens.png",
      depth: 500,
      position: 6,
      priority: 0,
    },
  },
  little_frog: {
    id: "little_frog",
    name: "Little Frog",
    icon: "assets/little_frog_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dt",
      path: "assets/little_frog.png",
      depth: 500,
      position: 1,
      priority: 1,
    },
  },
  little_muscley_dude: {
    id: "little_muscley_dude",
    name: "Little Muscley Dude",
    icon: "assets/little_muscley_dude_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Du",
      path: "assets/little_muscley_dude.png",
      depth: 1300,
      position: 2,
      priority: 2,
    },
  },
  lost_duck: {
    id: "lost_duck",
    name: "Lost Duck",
    icon: "assets/lost_duck_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dv",
      path: "assets/lost_duck.png",
      depth: 500,
      position: 8,
      priority: 0,
    },
  },
  lucky_charm: {
    id: "lucky_charm",
    name: "Lucky Charm",
    icon: "assets/lucky_charm_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dw",
      path: "assets/lucky_charm.png",
      depth: 401,
      position: 10,
      priority: 3,
    },
  },
  lumberjack_shirt: {
    id: "lumberjack_shirt",
    name: "Lumberjack Shirt",
    icon: "assets/lumberjack_shirt_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dx",
      path: "assets/lumberjack_shirt.png",
      depth: 301,
      position: 11,
      priority: 1,
    },
  },
  mammoth: {
    id: "mammoth",
    name: "Mammoth",
    icon: "assets/mammoth_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dy",
      path: "assets/mammoth.png",
      depth: 600,
      position: 8,
      priority: 4,
    },
  },
  mastery: {
    id: "mastery",
    name: "Mastery",
    icon: "assets/mastery_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Dz",
      path: "assets/mastery.png",
      depth: 700,
      position: 6,
      priority: 2,
    },
  },
  medal: {
    id: "medal",
    name: "Medal",
    icon: "assets/medal_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ea",
      path: "assets/medal.png",
      depth: 401,
      position: 10,
      priority: 2,
    },
  },
  medikit: {
    id: "medikit",
    name: "Medikit",
    icon: "assets/medikit_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Eb",
      path: "assets/medikit.png",
      depth: 650,
      position: 5,
      priority: 3,
    },
  },
  metal_detector: {
    id: "metal_detector",
    name: "Metal Detector",
    icon: "assets/metal_detector_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ec",
      path: "assets/metal_detector.png",
      depth: 1300,
      position: 3,
      priority: 2,
    },
  },
  metal_plate: {
    id: "metal_plate",
    name: "Metal Plate",
    icon: "assets/metal_plate_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ed",
      path: "assets/metal_plate.png",
      depth: 600,
      position: 8,
      priority: 3,
    },
  },
  missile: {
    id: "missile",
    name: "Missile",
    icon: "assets/missile_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ee",
      path: "assets/missile.png",
      depth: -500,
      position: 9,
      priority: 1,
    },
  },
  mouse: {
    id: "mouse",
    name: "Mouse",
    icon: "assets/mouse_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ef",
      path: "assets/mouse.png",
      depth: 601,
      position: 7,
      priority: 2,
    },
  },
  mushroom: {
    id: "mushroom",
    name: "Mushroom",
    icon: "assets/mushroom_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Eg",
      path: "assets/mushroom.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  mutation: {
    id: "mutation",
    name: "Mutation",
    icon: "assets/mutation_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Eh",
      path: "assets/mutation.png",
      depth: -100,
      position: 3,
      priority: 0,
    },
  },
  nail: {
    id: "nail",
    name: "Nail",
    icon: "assets/nail_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Ei",
      path: "assets/nail.png",
      depth: 1200,
      position: 3,
      priority: 3,
    },
  },
  night_goggles: {
    id: "night_goggles",
    name: "Night Goggles",
    icon: "assets/night_goggles_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ej",
      path: "assets/night_goggles.png",
      depth: 1300,
      position: 6,
      priority: 4,
    },
  },
  octopus: {
    id: "octopus",
    name: "Octopus",
    icon: "assets/octopus_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ek",
      path: "assets/octopus.png",
      depth: -75,
      position: 17,
      priority: 3,
    },
  },
  padding: {
    id: "padding",
    name: "Padding",
    icon: "assets/padding_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "El",
      path: "assets/padding.png",
      depth: 301,
      position: 11,
      priority: 2,
    },
  },
  panda: {
    id: "panda",
    name: "Panda",
    icon: "assets/panda_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Em",
      path: "assets/panda.png",
      depth: 500,
      position: 6,
      priority: 4,
    },
  },
  peaceful_bee: {
    id: "peaceful_bee",
    name: "Peaceful Bee",
    icon: "assets/peaceful_bee_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "En",
      path: "assets/peaceful_bee.png",
      depth: 50,
      position: 0,
      priority: 0,
    },
  },
  peacock: {
    id: "peacock",
    name: "Peacock",
    icon: "assets/peacock_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Eo",
      path: "assets/peacock.png",
      depth: -500,
      position: 9,
      priority: 3,
    },
  },
  pencil: {
    id: "pencil",
    name: "Pencil",
    icon: "assets/pencil_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ep",
      path: "assets/pencil.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  piggy_bank: {
    id: "piggy_bank",
    name: "Piggy Bank",
    icon: "assets/piggy_bank_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Eq",
      path: "assets/piggy_bank.png",
      depth: 600,
      position: 7,
      priority: 2,
    },
  },
  pile_of_books: {
    id: "pile_of_books",
    name: "Pile of Books",
    icon: "assets/pile_of_books_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Er",
      path: "assets/pile_of_books.png",
      depth: 1000,
      position: 4,
      priority: 0,
    },
  },
  plant: {
    id: "plant",
    name: "Plant",
    icon: "assets/plant_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/plant.png",
      hash: "Es",
      depth: 1200,
      position: 1,
      priority: 0,
    },
  },
  plastic_explosive: {
    id: "plastic_explosive",
    name: "Plastic Explosive",
    icon: "assets/plastic_explosive_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/plastic_explosive.png",
      hash: "Et",
      depth: -500,
      position: 9,
      priority: 0,
    },
  },
  pocket_factory: {
    id: "pocket_factory",
    name: "Pocket Factory",
    icon: "assets/pocket_factory_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/pocket_factory.png",
      hash: "Eu",
      depth: 500,
      position: 6,
      priority: 2,
    },
  },
  poisonous_tonic: {
    id: "poisonous_tonic",
    name: "Poisonous Tonic",
    icon: "assets/poisonous_tonic_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/poisonous_tonic.png",
      hash: "Ev",
      depth: 1300,
      position: 2,
      priority: 2,
    },
  },
  potato: {
    id: "potato",
    name: "Potato",
    icon: "assets/potato_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/potato.png",
      hash: "Ew",
      depth: 1,
      position: 14,
      priority: 3,
    },
  },
  potion: {
    id: "potion",
    name: "Potion",
    icon: "assets/potion_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/potion.png",
      hash: "Ex",
      depth: 250,
      position: 8,
      priority: 3,
    },
  },
  power_generator: {
    id: "power_generator",
    name: "Power Generator",
    icon: "assets/power_generator_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/power_generator.png",
      hash: "Ey",
      depth: 800,
      position: 3,
      priority: 2,
    },
  },
  propeller_hat: {
    id: "propeller_hat",
    name: "Propeller Hat",
    icon: "assets/propeller_hat_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/propeller_hat.png",
      hash: "Ez",
      depth: 1000,
      position: 4,
      priority: 1,
    },
  },
  pumpkin: {
    id: "pumpkin",
    name: "Pumpkin",
    icon: "assets/pumpkin_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/pumpkin.png",
      hash: "Fa",
      depth: 250,
      position: 8,
      priority: 0,
    },
  },
  recycling_machine: {
    id: "recycling_machine",
    name: "Recycling Machine",
    icon: "assets/recycling_machine_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/recycling_machine.png",
      hash: "Fb",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  retromations_hoodie: {
    id: "retromations_hoodie",
    name: "Retromation's Hoodie",
    icon: "assets/retromations_hoodie_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/retromations_hoodie.png",
      hash: "Fc",
      depth: 301,
      position: 11,
      priority: 4,
    },
  },
  rip_and_tear: {
    id: "rip_and_tear",
    name: "Rip and Tear",
    icon: "assets/rip_and_tear_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/rip_and_tear.png",
      hash: "Fd",
      depth: 250,
      position: 8,
      priority: 3,
    },
  },
  ritual: {
    id: "ritual",
    name: "Ritual",
    icon: "assets/ritual_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/ritual.png",
      hash: "Fe",
      depth: 500,
      position: 6,
      priority: 2,
    },
  },
  robot_arm: {
    id: "robot_arm",
    name: "Robot Arm",
    icon: "assets/robot_arm_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/robot_arm.png",
      hash: "Ff",
      depth: -100,
      position: 17,
      priority: 4,
    },
  },
  sad_tomato: {
    id: "sad_tomato",
    name: "Sad Tomato",
    icon: "assets/sad_tomato_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/sad_tomato.png",
      hash: "Fg",
      depth: 500,
      position: 6,
      priority: 3,
    },
  },
  scar: {
    id: "scar",
    name: "Scar",
    icon: "assets/scar_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/scar.png",
      hash: "Fh",
      depth: 100,
      position: 0,
      priority: 0,
    },
  },
  scared_sausage: {
    id: "scared_sausage",
    name: "Scared Sausage",
    icon: "assets/scared_sausage_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      path: "assets/scared_sausage.png",
      hash: "Fi",
      depth: 250,
      position: 8,
      priority: 0,
    },
  },
  scope: {
    id: "scope",
    name: "Scope",
    icon: "assets/scope_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fj",
      path: "assets/scope.png",
      depth: 1300,
      position: 1,
      priority: 2,
    },
  },
  shackles: {
    id: "shackles",
    name: "Shackles",
    icon: "assets/shackles_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fk",
      path: "assets/shackles.png",
      depth: 401,
      position: 10,
      priority: 2,
    },
  },
  shady_potion: {
    id: "shady_potion",
    name: "Shady Potion",
    icon: "assets/shady_potion_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fl",
      path: "assets/shady_potion.png",
      depth: 250,
      position: 8,
      priority: 2,
    },
  },
  shmoop: {
    id: "shmoop",
    name: "Shmoop",
    icon: "assets/shmoop_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fm",
      path: "assets/shmoop.png",
      depth: 20,
      position: 0,
      priority: 0,
    },
  },
  sifds_relic: {
    id: "sifds_relic",
    name: "SIFDS Relic",
    icon: "assets/sifds_relic_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fn",
      path: "assets/sifds_relic.png",
      depth: 700,
      position: 5,
      priority: 4,
    },
  },
  snail: {
    id: "snail",
    name: "Snail",
    icon: "assets/snail_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fo",
      path: "assets/snail.png",
      depth: -500,
      position: 9,
      priority: 1,
    },
  },
  snake: {
    id: "snake",
    name: "Snake",
    icon: "assets/snake_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fp",
      path: "assets/snake.png",
      depth: -50,
      position: 3,
      priority: 0,
    },
  },
  snowball: {
    id: "snowball",
    name: "Snowball",
    icon: "assets/snowball_icon.png",
    category: CATEGORY.ITEM,
    beta: true,
    appearance: {
      hash: "Fq",
      path: "assets/snowball.png",
      depth: 1000,
      position: 4,
      priority: 0,
    },
  },
  spicy_sauce: {
    id: "spicy_sauce",
    name: "Spicy Sauce",
    icon: "assets/spicy_sauce_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fr",
      path: "assets/spicy_sauce.png",
      depth: 500,
      position: 2,
      priority: 0,
    },
  },
  spider: {
    id: "spider",
    name: "Spider",
    icon: "assets/spider_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fs",
      path: "assets/spider.png",
      depth: 500,
      position: 6,
      priority: 2,
    },
  },
  statue: {
    id: "statue",
    name: "Statue",
    icon: "assets/statue_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ft",
      path: "assets/statue.png",
      depth: 600,
      position: 16,
      priority: 3,
    },
  },
  stone_skin: {
    id: "stone_skin",
    name: "Stone Skin",
    icon: "assets/stone_skin_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fu",
      path: "assets/stone_skin.png",
      depth: 1,
      position: 14,
      priority: 3,
    },
  },
  strange_book: {
    id: "strange_book",
    name: "Strange Book",
    icon: "assets/strange_book_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fv",
      path: "assets/strange_book.png",
      depth: 500,
      position: 6,
      priority: 3,
    },
  },
  sunglasses: {
    id: "sunglasses",
    name: "Sunglasses",
    icon: "assets/sunglasses_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fw",
      path: "assets/sunglasses.png",
      depth: 1200,
      position: 6,
      priority: 2,
    },
  },
  tardigrade: {
    id: "tardigrade",
    name: "Tardigrade",
    icon: "assets/tardigrade_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fx",
      path: "assets/tardigrade.png",
      depth: 250,
      position: 8,
      priority: 3,
    },
  },
  tentacle: {
    id: "tentacle",
    name: "Tentacle",
    icon: "assets/tentacle_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fy",
      path: "assets/tentacle.png",
      depth: -75,
      position: 17,
      priority: 2,
    },
  },
  terrified_onion: {
    id: "terrified_onion",
    name: "Terrified Onion",
    icon: "assets/terrified_onion_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Fz",
      path: "assets/terrified_onion.png",
      depth: 500,
      position: 6,
      priority: 1,
    },
  },
  toolbox: {
    id: "toolbox",
    name: "Toolbox",
    icon: "assets/toolbox_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ga",
      path: "assets/toolbox.png",
      depth: -500,
      position: 9,
      priority: 3,
    },
  },
  torture: {
    id: "torture",
    name: "Torture",
    icon: "assets/torture_icon.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        hash: "Gb",
        path: "assets/torture_0.png",
        depth: 600,
        position: 16,
        priority: 3,
      },
      {
        hash: "Gb",
        path: "assets/torture_1.png",
        depth: -100,
        position: 17,
        priority: 3,
      },
    ],
  },
  toxic_sludge: {
    id: "toxic_sludge",
    name: "Toxic Sludge",
    icon: "assets/toxic_sludge_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gc",
      path: "assets/toxic_sludge.png",
      depth: 1300,
      position: 2,
      priority: 0,
    },
  },
  tractor: {
    id: "tractor",
    name: "Tractor",
    icon: "assets/tractor_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gd",
      path: "assets/tractor.png",
      depth: 250,
      position: 8,
      priority: 3,
    },
  },
  tree: {
    id: "tree",
    name: "Tree",
    icon: "assets/tree_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Ge",
      path: "assets/tree.png",
      depth: 1001,
      position: 4,
      priority: 0,
    },
  },
  triangle_of_power: {
    id: "triangle_of_power",
    name: "Triangle of Power",
    icon: "assets/triangle_of_power_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gf",
      path: "assets/triangle_of_power.png",
      depth: 100,
      position: 5,
      priority: 3,
    },
  },
  ugly_tooth: {
    id: "ugly_tooth",
    name: "Ugly Tooth",
    icon: "assets/ugly_tooth_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gg",
      path: "assets/ugly_tooth.png",
      depth: 250,
      position: 8,
      priority: 0,
    },
  },
  vigilante_ring: {
    id: "vigilante_ring",
    name: "Vigilante Ring",
    icon: "assets/vigilante_ring_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gh",
      path: "assets/vigilante_ring.png",
      depth: 250,
      position: 8,
      priority: 3,
    },
  },
  warrior_helmet: {
    id: "warrior_helmet",
    name: "Warrior Helmet",
    icon: "assets/warrior_helmet_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gi",
      path: "assets/warrior_helmet.png",
      depth: 1000,
      position: 4,
      priority: 2,
    },
  },
  weird_food: {
    id: "weird_food",
    name: "Weird Food",
    icon: "assets/weird_food_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gj",
      path: "assets/weird_food.png",
      depth: 500,
      position: 6,
      priority: 0,
    },
  },
  weird_ghost: {
    id: "weird_ghost",
    name: "Weird Ghost",
    icon: "assets/weird_ghost_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gk",
      path: "assets/weird_ghost.png",
      depth: -50,
      position: 17,
      priority: 0,
    },
  },
  wheelbarrow: {
    id: "wheelbarrow",
    name: "Wheelbarrow",
    icon: "assets/wheelbarrow_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gl",
      path: "assets/wheelbarrow.png",
      depth: -501,
      position: 9,
      priority: 2,
    },
  },
  white_flag: {
    id: "white_flag",
    name: "White Flag",
    icon: "assets/white_flag_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gm",
      path: "assets/white_flag.png",
      depth: 1300,
      position: 1,
      priority: 2,
    },
  },
  wings: {
    id: "wings",
    name: "Wings",
    icon: "assets/wings_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gn",
      path: "assets/wings.png",
      depth: -1000,
      position: 9,
      priority: 3,
    },
  },
  wisdom: {
    id: "wisdom",
    name: "Wisdom",
    icon: "assets/wisdom_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Go",
      path: "assets/wisdom.png",
      depth: 550,
      position: 8,
      priority: 3,
    },
  },
  wolf_helmet: {
    id: "wolf_helmet",
    name: "Wolf Helmet",
    icon: "assets/wolf_helmet_icon.png",
    category: CATEGORY.ITEM,
    appearance: {
      hash: "Gp",
      path: "assets/wolf_helmet.png",
      depth: 1100,
      position: 4,
      priority: 4,
    },
  },
};
