import { useEffect, useState } from "react";
import "./App.scss";
import ItemListContainer from "./ui/ItemListContainer/ItemListContainer";
import html2canvas from "html2canvas";
import { StorageManager } from "./lib/storage/storage_manager";
import { useDispatch, useSelector } from "react-redux";
import { SettingsState } from "./store/settings";
import { ReactComponent as Discord } from "./icons/discord.svg";
import VersionElement, { version } from "./ui/VersionElement/VersionElement";
import {
  add,
  addWithoutSave,
  clearActive,
  HistoryState,
} from "./store/history";
import { Appearance, ItemManager } from "./lib/appearance/item_manager";
import { createBitmap, createBitmapWithBackground } from "./lib/utils";

function App() {
  const dispatch = useDispatch();
  const { active }: HistoryState = useSelector((state: any) => state.history);

  const {
    showAnimation,
    showLegs,
    showWeapons,
    showShadow,
    background,
  }: SettingsState = useSelector((state: any) => state.settings);

  const [versionInfoOpen, setVersionInfoOpen] = useState<Boolean>(false);

  const [selected, setSelected] = useState<string[]>(
    StorageManager.get("selected") ?? []
  );

  useEffect(() => {
    if (selected.length > 0) {
      dispatch(add(selected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (active.length === 0) {
      return;
    }
    setSelected(active);
    StorageManager.set("selected", active);
    // AppearanceManager.instance.filterByPriority(active);
  }, [active]);

  const downloadURI = (uri: string, name: string) => {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
  };

  const downloadAsImage = () => {
    let element: HTMLDivElement | null = document.getElementById(
      "AvatarContainer"
    ) as HTMLDivElement | null;
    if (!element) return;

    element.classList.remove("animate");

    html2canvas(element, {
      width: 380,
      height: 380,
      backgroundColor: null,
    }).then(async function (canvas) {
      if (showAnimation && element) element.classList.add("animate");
      const img =
        background === "none"
          ? await createBitmap(canvas)
          : await createBitmapWithBackground(canvas, background);
      if (!img) return;

      downloadURI(img, "my-brotato-avatar.png");
    });
  };

  return (
    <div
      className="App"
      style={{
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        background: `url(assets/${background}.png)`,
      }}
      onMouseMove={(e) => {
        if (!showWeapons) return;

        let box: HTMLElement | null = document.querySelector(".dagger");
        if (!box) return;

        let boxCenter = {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
        };
        let angle =
          Math.atan2(e.pageX - boxCenter.x, -(e.pageY - boxCenter.y)) *
            (180 / Math.PI) -
          90;
        box.style.transform = `rotate(${angle}deg)`;
      }}
    >
      <Discord
        style={{
          position: "fixed",
          left: 10,
          top: 10,
          width: 50,
          height: 50,
          zIndex: 999999999,
          cursor: "pointer",
        }}
        onClick={() => {
          console.log("clicked");
          //open link
          window.open("https://discord.gg/q43VqaTKPq", "_blank");
        }}
      />
      <div className="bortato-avatar-container">
        <div
          className={`animate-container ${showAnimation ? "animate" : ""}`}
          style={{}}
        >
          {showWeapons && (
            <img
              src="assets/dagger.png"
              alt="box"
              className="dagger"
              style={{
                position: "absolute",
                zIndex: 999999999,
                left: "57%",
                bottom: "40%",
                width: 275,
                transformOrigin: "50px 50%",
              }}
            />
          )}
          <div
            id="AvatarContainer"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {selected.map((item_key) => {
              const item = ItemManager.instance.getItemById(item_key);
              return item?.appearance.map((appearance: Appearance) => {
                return (
                  <img
                    key={appearance.path}
                    src={appearance.path}
                    className="avatar-item"
                    alt="hhallo"
                    style={{
                      zIndex: appearance.depth,
                    }}
                  />
                );
              });
            })}
            {showLegs && (
              <img
                key="assets/legs.png"
                src="assets/legs.png"
                alt="legs"
                className="avatar-item"
                style={{
                  zIndex: "var(--zi-legs)",
                }}
              />
            )}
            {showShadow && <div className="avatar-shadow"></div>}
          </div>
        </div>
      </div>
      {versionInfoOpen && (
        <div className="popup">
          <div className="background"></div>
          <div
            className="container"
            style={{
              maxHeight: "50%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 10,
                fontSize: 20,
                cursor: "pointer",
                height: "fit-content",
              }}
              onClick={() => {
                setVersionInfoOpen(false);
              }}
            >
              x
            </div>
            <span>ChangeLog</span>
            <VersionElement />
          </div>
        </div>
      )}
      <span
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          fontSize: 15,
          color: "gray",
          fontFamily: "Arial",
          fontWeight: "bold",
          padding: 10,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          setVersionInfoOpen(true);
        }}
      >
        {version}
        {/* {ItemManager.instance.itemListToHash(
          selected
            .map((item_key) => ItemManager.instance.getItemById(item_key))
            .filter((item) => item) as Item[]
        )} */}
      </span>
      <ItemListContainer
        onClick={(id: string) => {
          if (selected.includes(id)) {
            setSelected(selected.filter((item) => item !== id));
            dispatch(addWithoutSave(selected.filter((item) => item !== id)));
          } else {
            dispatch(addWithoutSave([...selected, id]));
          }
        }}
        onShuffle={(keys: string[]) => {
          dispatch(add(keys));
        }}
        onClear={() => {
          dispatch(clearActive());
          setSelected([]);
        }}
        onDownload={() => {
          downloadAsImage();
        }}
        selected={selected}
      />
    </div>
  );
}

export default App;
