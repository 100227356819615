import { useEffect, useState } from "react";
import "./CategorySelector.scss";

function CategorySelector({
  categories = [],
  onChange = () => {},
  activeIndex = 0,
  ...props
}: {
  activeIndex?: number;
  categories?: { [index: number]: string };
  onChange?: Function;
  [key: string]: any;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(activeIndex);

  useEffect(() => {
    document
      .getElementById("category-item-" + categories[selected])
      ?.scrollIntoView({ block: "center" });
  }, [categories, isOpen, selected]);

  return (
    <div className="category-selector-container" {...props}>
      {isOpen && (
        <div className="category-list">
          {Object.keys(categories).map((index) => {
            const num_index = parseInt(index);
            const name = categories[num_index];
            return (
              <div
                className={
                  "category-item " + (selected === num_index ? "selected" : "")
                }
                id={"category-item-" + name}
                key={num_index}
                onClick={() => {
                  setSelected(num_index);
                  setIsOpen(false);
                  onChange(num_index);
                }}
              >
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      )}
      <div
        className="category-label"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="category-item selected">
          <span>{categories[selected]}</span>
        </div>
      </div>
    </div>
  );
}

export default CategorySelector;
