import { configureStore } from "@reduxjs/toolkit";
import settings from "./settings";
import history from "./history";

export default configureStore({
  reducer: {
    settings,
    history,
  },
});
