import { CATEGORY, ITEM_OBJECT } from "../appearance_types";

export const characters: { [key: string]: ITEM_OBJECT } = {
  apprentice: {
    id: "apprentice",
    name: "Apprentice",
    icon: "assets/apprentice_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Gq",
        path: "assets/apprentice_eyes.png",
        position: 6,
        priority: 1,
        depth: 500,
        id: "apprentice_eyes",
        name: "Apprentice Eyes",
      },
      {
        hash: "Gr",
        path: "assets/apprentice_mouth.png",
        position: 8,
        priority: 1,
        depth: 250,
        id: "apprentice_mouth",
        name: "Apprentice Mouth",
      },
      {
        hash: "Gs",
        path: "assets/apprentice_robe.png",
        position: 11,
        priority: 1,
        depth: 320,
        id: "apprentice_robe",
        name: "Apprentice Robe",
      },
    ],
  },
  arms_dealer: {
    id: "arms_dealer",
    name: "Arms Dealer",
    icon: "assets/arms_dealer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Gt",
        path: "assets/arms_dealer_eyes.png",
        position: 6,
        priority: 2,
        depth: 1300,
        id: "arms_dealer_eyes",
        name: "Arms Dealer Eyes",
      },
      {
        hash: "Gu",
        path: "assets/arms_dealer_mouth.png",
        position: 8,
        priority: 2,
        depth: 250,
        id: "arms_dealer_mouth",
        name: "Arms Dealer Mouth",
      },
    ],
  },
  artificer: {
    id: "artificer",
    name: "Artificer",
    icon: "assets/artificer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Gv",
        path: "assets/artificer_eyes.png",
        position: 6,
        priority: 3,
        depth: 1300,
        id: "artificer_eyes",
        name: "Artificer Eyes",
      },
      {
        hash: "Gw",
        path: "assets/artificer_mouth.png",
        position: 8,
        priority: 3,
        depth: 250,
        id: "artificer_mouth",
        name: "Artificer Mouth",
      },
    ],
  },
  baby: {
    id: "baby",
    name: "Baby",
    icon: "assets/baby_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Gx",
        path: "assets/baby_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "baby_eyes",
        name: "Baby Eyes",
      },
      {
        hash: "Gy",
        path: "assets/baby_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "baby_mouth",
        name: "Baby Mouth",
      },
      {
        hash: "Gz",
        path: "assets/baby_hair.png",
        position: 0,
        priority: 0,
        depth: 50,
        id: "baby_hair",
        name: "Baby Hair",
      },
    ],
  },
  brawler: {
    id: "brawler",
    name: "Brawler",
    icon: "assets/brawler_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ha",
        path: "assets/brawler_eyes.png",
        position: 6,
        priority: 2,
        depth: 500,
        id: "brawler_eyes",
        name: "Brawler Eyes",
      },
      {
        hash: "Hb",
        path: "assets/brawler_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "brawler_mouth",
        name: "Brawler Mouth",
      },
    ],
  },
  bull: {
    id: "bull",
    name: "Bull",
    icon: "assets/bull_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hc",
        path: "assets/bull_eyes.png",
        position: 6,
        priority: 0,
        depth: 1200,
        id: "bull_eyes",
        name: "Bull Eyes",
      },
      {
        hash: "Hd",
        path: "assets/bull_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "bull_mouth",
        name: "Bull Mouth",
      },
    ],
  },
  chunky: {
    id: "chunky",
    name: "Chunky",
    icon: "assets/chunky_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "He",
        path: "assets/chunky_eyes.png",
        position: 6,
        priority: 2,
        depth: 500,
        id: "chunky_eyes",
        name: "Chunky Eyes",
      },
      {
        hash: "Hf",
        path: "assets/chunky_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "chunky_mouth",
        name: "Chunky Mouth",
      },
    ],
  },
  crazy: {
    id: "crazy",
    name: "Crazy",
    icon: "assets/crazy_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hg",
        path: "assets/crazy_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "crazy_eyes",
        name: "Crazy Eyes",
      },
      {
        hash: "Hh",
        path: "assets/crazy_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "crazy_mouth",
        name: "Crazy Mouth",
      },
    ],
  },
  cryptid: {
    id: "cryptid",
    name: "Cryptid",
    icon: "assets/cryptid_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hi",
        path: "assets/cryptid_eyes.png",
        position: 6,
        priority: 2,
        depth: 500,
        id: "cryptid_eyes",
        name: "Cryptid Eyes",
      },
      {
        hash: "Hj",
        path: "assets/cryptid_mouth.png",
        position: 8,
        priority: 2,
        depth: 250,
        id: "cryptid_mouth",
        name: "Cryptid Mouth",
      },
    ],
  },
  cyborg: {
    id: "cyborg",
    name: "Cyborg",
    icon: "assets/cyborg_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hk",
        path: "assets/cyborg_eyes.png",
        position: 6,
        priority: 4,
        depth: 1300,
        id: "cyborg_eyes",
        name: "Cyborg Eyes",
      },
      {
        hash: "Hl",
        path: "assets/cyborg_mouth.png",
        position: 8,
        priority: 1,
        depth: 550,
        id: "cyborg_mouth",
        name: "Cyborg Mouth",
      },
      {
        hash: "Hm",
        path: "assets/cyborg_skin.png",
        position: 14,
        priority: 1,
        depth: 1,
        id: "cyborg_skin",
        name: "Cyborg Skin",
      },
    ],
  },
  demon: {
    id: "demon",
    name: "Demon",
    icon: "assets/demon_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hn",
        path: "assets/demon_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "demon_eyes",
        name: "Demon Eyes",
      },
      {
        hash: "Ho",
        path: "assets/demon_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "demon_mouth",
        name: "Demon Mouth",
      },
      {
        hash: "Hp",
        path: "assets/demon_ear_0.png",
        position: 2,
        priority: 0,
        depth: 500,
        id: "demon_ear",
        name: "Demon Ear",
      },
      {
        hash: "Hq",
        path: "assets/demon_ear_1.png",
        position: 3,
        priority: 0,
        depth: -100,
        id: "demon_ear",
        name: "Demon Ear",
      },
      {
        hash: "Hr",
        path: "assets/demon_tail.png",
        position: 15,
        priority: 0,
        depth: -1100,
        id: "demon_tail",
        name: "Demon Tail",
      },
    ],
  },
  doctor: {
    id: "doctor",
    name: "Doctor",
    icon: "assets/doctor_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hs",
        path: "assets/doctor_eyes.png",
        position: 6,
        priority: 0,
        depth: 1300,
        id: "doctor_eyes",
        name: "Doctor Eyes",
      },
      {
        hash: "Ht",
        path: "assets/doctor_mouth.png",
        position: 8,
        priority: 2,
        depth: 540,
        id: "doctor_mouth",
        name: "Doctor Mouth",
      },
    ],
  },
  engineer: {
    id: "engineer",
    name: "Engineer",
    icon: "assets/engineer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: {
      hash: "Hu",
      path: "assets/engineer_mouth.png",
      position: 8,
      priority: 3,
      depth: 1250,
      id: "engineer_mouth",
      name: "Engineer Mouth",
    },
  },
  entrepreneur: {
    id: "entrepreneur",
    name: "Entrepreneur",
    icon: "assets/entrepreneur_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hv",
        path: "assets/entrepreneur_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "entrepreneur_eyes",
        name: "Entrepreneur Eyes",
      },
      {
        hash: "Hw",
        path: "assets/entrepreneur_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "entrepreneur_mouth",
        name: "Entrepreneur Mouth",
      },
      {
        hash: "Hx",
        path: "assets/entrepreneur_vest.png",
        position: 11,
        priority: 0,
        depth: 320,
        id: "entrepreneur_vest",
        name: "Entrepreneur Vest",
      },
    ],
  },
  explorer: {
    id: "explorer",
    name: "Explorer",
    icon: "assets/explorer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Hy",
        path: "assets/explorer_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "explorer_eyes",
        name: "Explorer Eyes",
      },
      {
        hash: "Hz",
        path: "assets/explorer_mouth.png",
        position: 8,
        priority: 2,
        depth: 550,
        id: "explorer_mouth",
        name: "Explorer Mouth",
      },
      {
        hash: "Ia",
        path: "assets/explorer_hair.png",
        position: 4,
        priority: 0,
        depth: 1000,
        id: "explorer_hair",
        name: "Explorer Hair",
      },
    ],
  },
  farmer: {
    id: "farmer",
    name: "Farmer",
    icon: "assets/farmer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ib",
        path: "assets/farmer_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "farmer_eyes",
        name: "Farmer Eyes",
      },
      {
        hash: "Ic",
        path: "assets/farmer_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "farmer_mouth",
        name: "Farmer Mouth",
      },
      {
        hash: "Id",
        path: "assets/farmer_nose.png",
        position: 7,
        priority: 0,
        depth: 600,
        id: "farmer_nose",
        name: "Farmer Nose",
      },
    ],
  },
  fisherman: {
    id: "fisherman",
    name: "Fisherman",
    icon: "assets/fisherman_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ie",
        path: "assets/fisherman_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "fisherman_eyes",
        name: "Fisherman Eyes",
      },
      {
        hash: "If",
        path: "assets/fisherman_mouth.png",
        position: 8,
        priority: 1,
        depth: 250,
        id: "fisherman_mouth",
        name: "Fisherman Mouth",
      },
      {
        hash: "Ig",
        path: "assets/fisherman_hat.png",
        position: 4,
        priority: 2,
        depth: 1000,
        id: "fisherman_hat",
        name: "Fisherman Hat",
      },
    ],
  },
  generalist: {
    id: "generalist",
    name: "Generalist",
    icon: "assets/generalist_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ih",
        path: "assets/generalist_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "generalist_eyes",
        name: "Generalist Eyes",
      },
      {
        hash: "Ii",
        path: "assets/generalist_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "generalist_mouth",
        name: "Generalist Mouth",
      },
    ],
  },
  ghost: {
    id: "ghost",
    name: "Ghost",
    icon: "assets/ghost_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ij",
        path: "assets/ghost_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "ghost_eyes",
        name: "Ghost Eyes",
      },
      {
        hash: "Ik",
        path: "assets/ghost_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "ghost_mouth",
        name: "Ghost Mouth",
      },
    ],
  },
  gladiator: {
    id: "gladiator",
    name: "Gladiator",
    icon: "assets/gladiator_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Il",
        path: "assets/gladiator_eyes.png",
        position: 6,
        priority: 1,
        depth: 500,
        id: "gladiator_eyes",
        name: "Gladiator Eyes",
      },
      {
        hash: "Im",
        path: "assets/gladiator_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "gladiator_mouth",
        name: "Gladiator Mouth",
      },
      {
        hash: "In",
        path: "assets/gladiator_helmet.png",
        position: 4,
        priority: 3,
        depth: 1000,
        id: "gladiator_helmet",
        name: "Gladiator Helmet",
      },
    ],
  },
  glutton: {
    id: "glutton",
    name: "Glutton",
    icon: "assets/glutton_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Io",
        path: "assets/glutton_eyes.png",
        position: 6,
        priority: 1,
        depth: 500,
        id: "glutton_eyes",
        name: "Glutton Eyes",
      },
      {
        hash: "Ip",
        path: "assets/glutton_mouth.png",
        position: 8,
        priority: 1,
        depth: 250,
        id: "glutton_mouth",
        name: "Glutton Mouth",
      },
    ],
  },
  golem: {
    id: "golem",
    name: "Golem",
    icon: "assets/golem_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Iq",
        path: "assets/golem_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "golem_eyes",
        name: "Golem Eyes",
      },
      {
        hash: "Ir",
        path: "assets/golem_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "golem_mouth",
        name: "Golem Mouth",
      },
      {
        hash: "Is",
        path: "assets/golem_skin.png",
        position: 14,
        priority: 3,
        depth: 1,
        id: "golem_skin",
        name: "Golem Skin",
      },
    ],
  },
  hunter: {
    id: "hunter",
    name: "Hunter",
    icon: "assets/hunter_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "It",
        path: "assets/hunter_eyes.png",
        position: 6,
        priority: 3,
        depth: 1300,
        id: "hunter_eyes",
        name: "Hunter Eyes",
      },
      {
        hash: "Iu",
        path: "assets/hunter_mouth.png",
        position: 8,
        priority: 2,
        depth: 540,
        id: "hunter_mouth",
        name: "Hunter Mouth",
      },
      {
        hash: "Iv",
        path: "assets/hunter_hat.png",
        position: 4,
        priority: 3,
        depth: 1000,
        id: "hunter_hat",
        name: "Hunter Hat",
      },
    ],
  },
  jack: {
    id: "jack",
    name: "Jack",
    icon: "assets/jack_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Iw",
        path: "assets/jack_eyes.png",
        position: 6,
        priority: 1,
        depth: 500,
        id: "jack_eyes",
        name: "Jack Eyes",
      },
      {
        hash: "Ix",
        path: "assets/jack_mouth.png",
        position: 8,
        priority: 1,
        depth: 250,
        id: "jack_mouth",
        name: "Jack Mouth",
      },
      {
        hash: "Iy",
        path: "assets/jack_nose.png",
        position: 7,
        priority: 0,
        depth: 600,
        id: "jack_nose",
        name: "Jack Nose",
      },
    ],
  },
  king: {
    id: "king",
    name: "King",
    icon: "assets/king_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Iz",
        path: "assets/king_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "king_eyes",
        name: "King Eyes",
      },
      {
        hash: "Ja",
        path: "assets/king_mouth.png",
        position: 8,
        priority: 2,
        depth: 250,
        id: "king_mouth",
        name: "King Mouth",
      },
      {
        hash: "Jb",
        path: "assets/king_crown.png",
        position: 4,
        priority: 3,
        depth: 900,
        id: "king_crown",
        name: "King Crown",
      },
    ],
  },
  knight: {
    id: "knight",
    name: "Knight",
    icon: "assets/knight_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jc",
        path: "assets/knight_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "knight_eyes",
        name: "Knight Eyes",
      },
      {
        hash: "Jd",
        path: "assets/knight_mouth.png",
        position: 8,
        priority: 2,
        depth: 250,
        id: "knight-mouth",
        name: "Knight Mouth",
      },
      {
        hash: "Je",
        path: "assets/knight_helmet.png",
        position: 4,
        priority: 3,
        depth: 1000,
        id: "knight_helmet",
        name: "Knight Helmet",
      },
    ],
  },
  lich: {
    id: "lich",
    name: "Lich",
    icon: "assets/lich_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jf",
        path: "assets/lich_eyes.png",
        position: 6,
        priority: 2,
        depth: 500,
        id: "lich_eyes",
        name: "Lich Eyes",
      },
      {
        hash: "Jg",
        path: "assets/lich_mouth.png",
        position: 8,
        priority: 3,
        depth: 250,
        id: "lich_mouth",
        name: "Lich Mouth",
      },
      {
        hash: "Jh",
        path: "assets/lich_diadem.png",
        position: 5,
        priority: 3,
        depth: 50,
        id: "lich_diadem",
        name: "Lich Diadem",
      },
    ],
  },
  loud: {
    id: "loud",
    name: "Loud",
    icon: "assets/loud_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ji",
        path: "assets/loud_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "loud_eyes",
        name: "Loud Eyes",
      },
      {
        hash: "Jj",
        path: "assets/loud_mouth.png",
        position: 8,
        priority: 3,
        depth: 250,
        id: "loud_mouth",
        name: "Loud Mouth",
      },
    ],
  },
  lucky: {
    id: "lucky",
    name: "Lucky",
    icon: "assets/lucky_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jk",
        path: "assets/lucky_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "lucky_eyes",
        name: "Lucky Eyes",
      },
      {
        hash: "Jl",
        path: "assets/lucky_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "lucky_mouth",
        name: "Lucky Mouth",
      },
    ],
  },
  mage: {
    id: "mage",
    name: "Mage",
    icon: "assets/mage_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jm",
        path: "assets/mage_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "mage_eyes",
        name: "Mage Eyes",
      },
      {
        hash: "Jn",
        path: "assets/mage_mouth.png",
        position: 8,
        priority: 3,
        depth: 500,
        id: "mage_mouth",
        name: "Mage Mouth",
      },
    ],
  },
  masochist: {
    id: "masochist",
    name: "Masochist",
    icon: "assets/masochist_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jo",
        path: "assets/masochist_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "masochist_eyes",
        name: "Masochist Eyes",
      },
      {
        hash: "Jp",
        path: "assets/masochist_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "masochist_mouth",
        name: "Masochist Mouth",
      },
    ],
  },
  multitasker: {
    id: "multitasker",
    name: "Multitasker",
    icon: "assets/multitasker_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jq",
        path: "assets/multitasker_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "multitasker_eyes",
        name: "Multitasker Eyes",
      },
      {
        hash: "Jr",
        path: "assets/multitasker_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "multitasker_mouth",
        name: "Multitasker Mouth",
      },
    ],
  },
  mutant: {
    id: "mutant",
    name: "Mutant",
    icon: "assets/mutant_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Js",
        path: "assets/mutant_eyes.png",
        position: 6,
        priority: 3,
        depth: 500,
        id: "mutant_eyes",
        name: "Mutant Eyes",
      },
      {
        hash: "Jt",
        path: "assets/mutant_mouth.png",
        position: 8,
        priority: 3,
        depth: 250,
        id: "mutant_mouth",
        name: "Mutant Mouth",
      },
    ],
  },
  old: {
    id: "old",
    name: "Old",
    icon: "assets/old_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ju",
        path: "assets/old_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "old_eyes",
        name: "Old Eyes",
      },
      {
        hash: "Jv",
        path: "assets/old_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "old_mouth",
        name: "Old Mouth",
      },
    ],
  },
  one_armed: {
    id: "one_armed",
    name: "One Armed",
    icon: "assets/one_armed_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jw",
        path: "assets/one_armed_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "one_armed_eyes",
        name: "One Armed Eyes",
      },
      {
        hash: "Jx",
        path: "assets/one_armed_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "one_armed_mouth",
        name: "One Armed Mouth",
      },
    ],
  },
  pacifist: {
    id: "pacifist",
    name: "Pacifist",
    icon: "assets/pacifist_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Jy",
        path: "assets/pacifist_eyes.png",
        position: 6,
        priority: 3,
        depth: 500,
        id: "pacifist_eyes",
        name: "Pacifist Eyes",
      },
      {
        hash: "Jz",
        path: "assets/pacifist_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "pacifist_mouth",
        name: "Pacifist Mouth",
      },
    ],
  },
  ranger: {
    id: "ranger",
    name: "Ranger",
    icon: "assets/ranger_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ka",
        path: "assets/ranger_eyes.png",
        position: 6,
        priority: 3,
        depth: 500,
        id: "ranger_eyes",
        name: "Ranger Eyes",
      },
      {
        hash: "Kb",
        path: "assets/ranger_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "ranger_mouth",
        name: "Ranger Mouth",
      },
    ],
  },
  renegade: {
    id: "renegade",
    name: "Renegade",
    icon: "assets/renegade_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kc",
        path: "assets/renegade_eyes.png",
        position: 6,
        priority: 1,
        depth: 500,
        id: "renegade_eyes",
        name: "Renegade Eyes",
      },
      {
        hash: "Kd",
        path: "assets/renegade_mouth.png",
        position: 8,
        priority: 2,
        depth: 250,
        id: "renegade_mouth",
        name: "Renegade Mouth",
      },
      {
        hash: "Ke",
        path: "assets/renegade_hair.png",
        position: 4,
        priority: 3,
        depth: 800,
        id: "renegade_hair",
        name: "Renegade Hair",
      },
    ],
  },
  saver: {
    id: "saver",
    name: "Saver",
    icon: "assets/saver_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kf",
        path: "assets/saver_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "saver_eyes",
        name: "Saver Eyes",
      },
      {
        hash: "Kg",
        path: "assets/saver_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "saver_mouth",
        name: "Saver Mouth",
      },
    ],
  },
  sick: {
    id: "sick",
    name: "Sick",
    icon: "assets/sick_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kh",
        path: "assets/sick_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "sick_eyes",
        name: "Sick Eyes",
      },
      {
        hash: "Ki",
        path: "assets/sick_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "sick_mouth",
        name: "Sick Mouth",
      },
    ],
  },
  soldier: {
    id: "soldier",
    name: "Soldier",
    icon: "assets/soldier_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kj",
        path: "assets/soldier_eyes.png",
        position: 6,
        priority: 3,
        depth: 1200,
        id: "soldier_eyes",
        name: "Soldier Eyes",
      },
      {
        hash: "Kk",
        path: "assets/soldier_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "soldier_mouth",
        name: "Soldier Mouth",
      },
    ],
  },
  speedy: {
    id: "speedy",
    name: "Speedy",
    icon: "assets/speedy_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kl",
        path: "assets/speedy_eyes.png",
        position: 6,
        priority: 3,
        depth: 1200,
        id: "speedy_eyes",
        name: "Speed Eyes",
      },
      {
        hash: "Km",
        path: "assets/speedy_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "speedy_mouth",
        name: "Speedy Mouth",
      },
    ],
  },
  streamer: {
    id: "streamer",
    name: "Streamer",
    icon: "assets/streamer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kn",
        path: "assets/streamer_eyes.png",
        position: 6,
        priority: 0,
        depth: 1300,
        id: "streamer_eyes",
        name: "Streamer Eyes",
      },
      {
        hash: "Ko",
        path: "assets/streamer_gamepad.png",
        position: 0,
        priority: 4,
        depth: 540,
        id: "streamer_gamepad",
        name: "Streamer Gamepad",
      },
    ],
  },
  technomage: {
    id: "technomage",
    name: "Technomage",
    icon: "assets/technomage_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kp",
        path: "assets/technomage_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "technomage_eyes",
        name: "Technomage Eyes",
      },
      {
        hash: "Kq",
        path: "assets/technomage_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "technomage_mouth",
        name: "Technomage Mouth",
      },
    ],
  },
  vagabond: {
    id: "vagabond",
    name: "Vagabond",
    icon: "assets/vagabond_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kr",
        path: "assets/vagabond_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "vagabond_eyes",
        name: "Vagabond Eyes",
      },
      {
        hash: "Ks",
        path: "assets/vagabond_mouth.png",
        position: 8,
        priority: 0,
        depth: 500,
        id: "vagabond_mouth",
        name: "Vagabond Mouth",
      },
      {
        hash: "Kt",
        path: "assets/vagabond_belt.png",
        position: 10,
        priority: 1,
        depth: 500,
        id: "vagabond_belt",
        name: "Vagabond Belt",
      },
      {
        hash: "Ku",
        path: "assets/vagabond_back.png",
        position: 9,
        priority: 1,
        depth: -1000,
        id: "vagabond_back",
        name: "Vagabond Back",
      },
    ],
  },
  vampire: {
    id: "vampire",
    name: "Vampire",
    icon: "assets/vampire_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Kv",
        path: "assets/vampire_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "vampire_eyes",
        name: "Vampire Eyes",
      },
      {
        hash: "Kw",
        path: "assets/vampire_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "vampire_mouth",
        name: "Vampire Mouth",
      },
      {
        hash: "Kx",
        path: "assets/vampire_shirt.png",
        position: 11,
        priority: 0,
        depth: 320,
        id: "vampire_shirt",
        name: "Vampire Shirt",
      },
    ],
  },
  well_rounded: {
    id: "well_rounded",
    name: "Well Rounded",
    icon: "assets/well_rounded_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "Ky",
        path: "assets/well_rounded_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "well_rounded_eyes",
        name: "Well Rounded Eyes",
      },
      {
        hash: "Kz",
        path: "assets/well_rounded_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "well_rounded_mouth",
        name: "Well Rounded Mouth",
      },
    ],
  },
  wildling: {
    id: "wildling",
    name: "Wildling",
    icon: "assets/wildling_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        hash: "La",
        path: "assets/wildling_eyes.png",
        position: 6,
        priority: 0,
        depth: 500,
        id: "wildling_eyes",
        name: "Wildling Eyes",
      },
      {
        hash: "Lb",
        path: "assets/wildling_mouth.png",
        position: 8,
        priority: 0,
        depth: 250,
        id: "wildling_mouth",
        name: "Wildling Mouth",
      },
    ],
  },
};
