import "./ButtonGroup.scss";
import { AudioManager } from "../../lib/audio/audioManager";
import { useState } from "react";

function ButtonGroup({
  values = [],
  selected = "",
  onClick = () => {},
  onRender = () => {},
}: {
  values: string[];
  selected: string;
  onClick: Function;
  onRender: Function;
}) {
  const [_selected, _setSelected] = useState(selected);

  return (
    <div
      onClick={() => {
        AudioManager.click();
      }}
      className="button-group-container round-container"
      style={{}}
    >
      {values.map((key, index) => {
        return (
          <div
            className={`button-group-button ${
              _selected === key ? "selected" : ""
            }`}
            key={index}
            onClick={() => {
              _selected !== key && _setSelected(key);
              onClick(key);
            }}
          >
            {onRender(key)}
          </div>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
