import "./ItemListContainer.scss";
import ItemElement from "../ItemElement/ItemElement";

import { useState } from "react";
import Settings from "../Settings/Settings";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as ShuffleIcon } from "../../icons/shuffle.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ReactComponent as ClearIcon } from "../../icons/clear.svg";
import { ReactComponent as UndoIcon } from "../../icons/undo.svg";
import { ReactComponent as RedoIcon } from "../../icons/redo.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ItemManager, Item } from "../../lib/appearance/item_manager";
import CategorySelector from "../CategorySelector/CategorySelector";
import { AudioManager } from "../../lib/audio/audioManager";
import { useDispatch } from "react-redux";
import { redo, undo } from "../../store/history";

const SETTINGS_INDEX = -10;
const ALL_INDEX = -3;

function ItemListContainer({
  selected = [],
  onClick = () => {},
  onShuffle = (keys: string[]) => {},
  onClear = () => {},
  onDownload = () => {},
}: {
  group?: { name: string; items: any[] };
  filter?: string;
  selected?: string[];
  onClick?: Function;
  onShuffle?: Function;
  onClear?: Function;
  onDownload?: Function;
}) {
  const dispatch = useDispatch();

  const groups = ItemManager.instance.groups;
  //tab controll state for active tab
  const [activeTab, setActiveTab] = useState(0);
  const [lastActiveTab, setLastActiveTab] = useState(0);

  const [filterText, setFilterText] = useState<string>("");

  const renderSpecialTabContent = (index: number): JSX.Element => {
    switch (index) {
      case SETTINGS_INDEX:
        return renderSettingsContent();
      case ALL_INDEX:
        return renderAllContent();
      default:
        return <div></div>;
    }
  };

  const filterItems = (item: Item): boolean => {
    return item.name.toLowerCase().includes(filterText.toLowerCase());
  };

  const renderSettingsContent = (): JSX.Element => {
    return <Settings />;
  };

  const renderAllContent = (): JSX.Element => {
    const tempKeys: string[] = [];
    return (
      <>
        {Object.keys(groups)
          .reduce((p: Item[], c) => [...p, ...groups[c]], [])
          .filter((item) => filterItems(item))
          .map((item) => {
            if (tempKeys.includes(item.name)) {
              return null;
            }
            tempKeys.push(item.name);
            return (
              <ItemElement
                item={item}
                groupName={Object.keys(groups)[activeTab]}
                key={"all_" + item.name}
                selected={selected.includes(item.id)}
                onClick={() => {
                  onClick(item.id);
                }}
              >
                {item.name}
              </ItemElement>
            );
          })}
      </>
    );
  };

  return (
    <div className="item-list-container">
      {![SETTINGS_INDEX].includes(activeTab) && (
        <div className="top-bar">
          <input
            className="filter-input"
            type="search"
            placeholder="Search"
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
          <CategorySelector
            activeIndex={activeTab}
            categories={Object.keys(groups).reduce(
              (p, c, ci) => ({ ...p, [ci]: c }),
              { [ALL_INDEX]: "All" }
            )}
            onChange={(index: number) => {
              AudioManager.click();
              setActiveTab(index);
            }}
            style={{ flex: 1 }}
          />
        </div>
      )}
      <div className="tab-content-container">
        <div className="tab-content">
          {activeTab > -1
            ? Object.values(groups)
                [activeTab].filter((item) => filterItems(item))
                .map((group) => {
                  return (
                    <ItemElement
                      key={group.name}
                      item={group}
                      groupName={Object.keys(groups)[activeTab]}
                      selected={selected.includes(group.id)}
                      onClick={() => {
                        onClick(group.id);
                      }}
                    >
                      {group.name}
                    </ItemElement>
                  );
                })
            : renderSpecialTabContent(activeTab)}
        </div>
      </div>
      <div className="bottom-bar">
        <div className="buttons-container">
          <div
            onClick={() => {
              AudioManager.click();
              onDownload();
            }}
            className={"download round-interactive"}
          >
            <DownloadIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              onClear();
            }}
            className={"clear round-interactive"}
          >
            <ClearIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              dispatch(undo());
            }}
            className={"undo round-interactive"}
          >
            <UndoIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              dispatch(redo());
            }}
            className={"redo round-interactive"}
          >
            <RedoIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              onShuffle(ItemManager.instance.shuffle());
            }}
            className={"shuffle round-interactive"}
          >
            <ShuffleIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              if (activeTab === SETTINGS_INDEX) {
                setActiveTab(lastActiveTab);
              } else {
                setLastActiveTab(activeTab);
                setActiveTab(SETTINGS_INDEX);
              }
            }}
            className={`settings round-interactive ${
              activeTab === SETTINGS_INDEX ? "active" : ""
            }`}
          >
            {activeTab === SETTINGS_INDEX ? (
              <CloseIcon className="settings" />
            ) : (
              <SettingsIcon className="settings" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemListContainer;
