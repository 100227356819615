import store from "../../store/store";

const audio = new Audio("../assets/Button Press.wav");

export class AudioManager {
  private static isPlaying = false;
  private constructor() {}

  public static click() {
    if (this.isPlaying || !store.getState().settings.audio) return;
    this.isPlaying = true;
    audio.play();
    audio.volume = 0.1;
    audio.onended = () => {
      this.isPlaying = false;
    };
  }

  public static get(key: string): any {
    return JSON.parse(localStorage.getItem(key) || "null");
  }
}
